














import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class VideoView extends Vue {
  @Prop() private url!: string
  @Prop({ default: true }) private isNeedTitle!: boolean

  close() {
    this.$emit('close')
  }
}
