






























/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop } from 'vue-property-decorator'
import Dialog from '@/components/Dialog/index.vue'

@Component({
  components: {
    Dialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isShow!: boolean
  @Prop({ default: false }) private loading!: boolean

  name = ''
  tel = ''

  closeDialog() {
    this.name = ''
    this.tel = ''
    this.$emit('close', '')
  }
  dialogConfirm() {
    if (this.name === '') {
      this.$message.warning('请填写师傅姓名')
    } else if (this.tel === '') {
      this.$message.warning('请填写联系电话')
    } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.tel)) {
      this.$message.warning('请正确填写联系电话')
    } else {
      this.$emit('optSuccess', {
        repairerName: this.name,
        repairerMobile: this.tel
      })

      this.name = ''
      this.tel = ''
    }
  }
}
