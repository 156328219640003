

























































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Player from 'xgplayer'
import ImgView from '@/components/ImgView/index.vue'
import VideoView from '@/components/VideoView/index.vue'
import RemarkModal from '@/views/common/remark-modal.vue'
import TipModal from '@/views/common/tip-modal.vue'
import DistributeModal from './distribute-modal.vue'
import PriceModal from './price-modal.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { Avatar, InputNumber } from 'element-ui'
Vue.use(Avatar)
Vue.use(InputNumber)

@Component({
  components: {
    ImgView,
    VideoView,
    RemarkModal,
    TipModal,
    DistributeModal,
    PriceModal
  }
})
export default class extends Vue {
  $constants: any
  // 报修状态
  repairStatusDicts: any = []
  loading = false
  repairInfo: any = {}
  remark = ''
  // 价格
  price = '0'
  // 编辑维修人员姓名
  editName = ''
  canEditName = false
  // 编辑维修人员手机号
  editPhone = ''
  canEditPhone = false

  imgUrl = ''
  videoUrl = ''

  openWhichModal = ''

  async beforeCreate() {
    // 获取相关字典
    // 报修状态
    this.repairStatusDicts = await getDicts('REPAIR_STATUS')
  }
  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }
  created() {
    this.getRepairDetail(this.$route.query.id)
  }
  //点击复制电话号码
  onCopy() {
    this.$message.success('复制电话号码成功')
  }
  //查询详情
  getRepairDetail(id: any) {
    this.loading = true
    this.$api.property
      .getRepairDetail(id)
      .then((res: any) => {
        const data = res.data
        this.loading = false
        if (data && data.success) {
          this.repairInfo = data.data
          this.remark = this.repairInfo.remark
          this.editName = this.repairInfo.repairerName
          this.editPhone = this.repairInfo.repairerMobile
          this.price = this.repairInfo.price || 0
          if (
            this.repairInfo.video &&
            !(document.querySelector('#repairVideo') as any).innerHTML
          ) {
            new Player({
              id: 'repairVideo',
              url: this.$constants.common.imgPrefix + this.repairInfo.video,
              width: '100%',
              height: '140',
              autoplay: true
            })
          }
        }
      })
      .catch(() => {
        this.loading = false
      })
  }
  // 进行中
  addHandling() {
    this.$api.property
      .repairHandling({
        id: this.repairInfo.id
      })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          this.$message.success(data.msg)
          this.getRepairDetail(this.$route.query.id)
          this.closeDialog()
        }
      })
  }

  // 完成
  addComplete() {
    this.$api.property
      .addComplete({
        id: this.repairInfo.id
      })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          this.$message.success(data.msg)
          this.getRepairDetail(this.$route.query.id)
          this.closeDialog()
        }
      })
  }

  // 修改
  saveRemark() {
    this.$api.property
      .editRepairDetail({
        id: this.repairInfo.id,
        remark: this.remark,
        price: this.price,
        repairerMobile: this.editPhone,
        repairerName: this.editName
      })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          this.$message.success(data.msg)
          this.closeDialog()
          this.goback()
        }
      })
  }

  // 维修人员派发
  distributeStaff(record: any) {
    this.$api.property
      .distributeStaff({
        id: this.repairInfo.id,
        ...record
      })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          this.$message.success(data.msg)
          this.getRepairDetail(this.$route.query.id)
          this.closeDialog()
        }
      })
  }
  // 完成添加价格
  addPrice(price: any) {
    this.$api.property
      .addPrice({
        id: this.repairInfo.id,
        price
      })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          this.$message.success(data.msg)
          this.getRepairDetail(this.$route.query.id)
          this.closeDialog()
        }
      })
  }
  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  closeVideo() {
    this.videoUrl = ''
  }

  // 关闭对话框
  closeDialog() {
    this.openWhichModal = ''
  }

  // 返回
  goback() {
    this.$router.push('/property/repair')
  }
}
